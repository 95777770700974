export const alignmentOptions = [
  {
    name: 'left',
    value: 'left',
  },
  {
    name: 'center',
    value: 'center',
  },
  {
    name: 'right',
    value: 'right',
  },
];

export const columnOptions = [
  {
    value: 1,
    name: 'document-wide',
  },
  {
    value: 2,
    name: 'section-wide',
  },
];

export const showLogoOptions = [
  {
    name: 'account_logo',
    value: 0,
  },
  {
    name: 'primary_location_logo',
    value: 1,
  },
  {
    name: 'secondary_location_logo',
    value: 2,
  },
];

export const printLogoPositionOptions = [
  {
    name: 'top',
    value: 1,
  },
  {
    name: 'bottom',
    value: 2,
  },
];

export const highlightSectionsTypeOptions = [
  {
    value: 0,
    name: 'none',
  },
  {
    value: 1,
    name: 'framed',
  },
  {
    value: 2,
    name: 'background',
  },
  {
    value: 3,
    name: 'bold',
  },
  {
    value: 4,
    name: 'level-up',
  },
  {
    value: 5,
    name: 'centered',
  },
];

export const highlightTypeOptions = [
  {
    value: 0,
    name: 'none',
  },
  {
    value: 1,
    name: 'framed',
  },
  {
    value: 2,
    name: 'background',
  },
  {
    value: 3,
    name: 'bold',
  },
  {
    value: 4,
    name: 'level-up',
  },
];

// NOTE: pictogram always needs to be the last option
export const allergenFormatOptions = [
  {
    name: 'icon',
    value: 0,
  },
  {
    name: 'icon_full',
    value: 1,
  },
  {
    name: 'subscript',
    value: 2,
  },
  {
    name: 'superscript',
    value: 3,
  },
  {
    name: 'inline',
    value: 6,
  },
  {
    name: 'colour',
    value: 4,
  },
  {
    name: 'pictogram',
    value: 5,
  },
];

// NOTE: pictogram always needs to be the last option
export const additiveFormatOptions = [
  {
    name: 'circled',
    value: 0,
  },
  {
    name: 'circled_full',
    value: 1,
  },
  {
    name: 'subscript',
    value: 2,
  },
  {
    name: 'superscript',
    value: 3,
  },
  {
    name: 'inline',
    value: 5,
  },
  {
    name: 'pictogram',
    value: 4,
  },
];

// NOTE: pictogram always needs to be the last option
export const labelFormatOptions = [
  {
    name: 'icon',
    value: 0,
  },
  {
    name: 'icon_full',
    value: 1,
  },
  {
    name: 'subscript',
    value: 3,
  },
  {
    name: 'superscript',
    value: 4,
  },
  {
    name: 'inline',
    value: 5,
  },
  {
    name: 'pictogram',
    value: 2,
  },
];

export const pageNumbersFormat = [
  {
    name: 'standard',
    value: 1,
  },
  {
    name: 'full',
    value: 2,
  },
];

export const imagesFormatOptions = [
  {
    name: 'original',
    value: 0,
  },
  {
    name: 'square',
    value: 1,
  },
  {
    name: 'square_contains',
    value: 2,
  },
];

export const showInModalOptions = [
  {
    name: 'image',
    value: 'image',
  },
  {
    name: 'image_section',
    value: 'image_section',
  },
];

export const showLegendOptions = [
  {
    name: 'list',
    value: 0,
  },
  {
    name: 'columns',
    value: 1,
  },
  {
    name: 'inline',
    value: 2,
  },
  {
    name: 'inline_with_titles',
    value: 3,
  },
  {
    name: 'each_page',
    value: 4,
  },
];

export const labelPositionOptions = [
  {
    name: 'before',
    value: 1,
  },
  {
    name: 'after',
    value: 2,
  },
];

export const priceFormats = [
  {
    name: 'standard',
    value: 1,
  },
  {
    name: 'short',
    value: 2,
  },
];

export const priceShowCurrencyOptions = [
  {
    name: 'none',
    value: 0,
  },
  {
    name: 'symbol',
    value: 1,
  },
  {
    name: 'code',
    value: 2,
  },
];

export const checkboxPositionOptions = [
  {
    name: 'left',
    value: 1,
  },
  {
    name: 'right',
    value: 2,
  },
];

export const checkboxTypeOptions = [
  {
    name: 'colour',
    value: 1,
  },
  {
    name: 'border',
    value: 2,
  },
  {
    name: 'line_below',
    value: 3,
  },
];

export const leaderOptions = [
  {
    name: 'none',
    value: null,
  },
  {
    name: 'dotted',
    value: 1,
  },
  {
    name: 'solid',
    value: 2,
  },
];

export const coloursOtherFields = ['divider', 'panel', 'decorator'];

export const backgroundTextFields = [
  'title',
  'subtitle',
  'section',
  'subsection',
  'text',
  'footnote',
];

export const dimensions = [
  {
    name: 'din_a3',
    value: 'A3',
  },
  {
    name: 'din_a3_landscape',
    value: 'A3_landscape',
  },
  {
    name: 'din_a4',
    value: 'A4',
  },
  {
    name: 'din_a4_landscape',
    value: 'A4_landscape',
  },
  {
    name: 'din_a5',
    value: 'A5',
  },
  {
    name: 'legal',
    value: 'legal',
  },
  {
    name: 'legal_landscape',
    value: 'legal_landscape',
  },
  {
    name: 'letter',
    value: 'letter',
  },
  {
    name: 'letter_landscape',
    value: 'letter_landscape',
  },
  {
    name: 'custom',
    value: 'custom',
  },
];

export const foldingOptions = [
  {
    name: 'none',
    value: null,
  },
  {
    name: 'half_fold',
    value: 0,
  },
  {
    name: 'brochure',
    value: 1,
  },
  {
    name: 'trifold',
    value: 2,
  },
  {
    name: 'four_panel',
    value: 3,
  },
];

export const trifoldOptions = [
  {
    name: 'nofold',
    value: 2,
  },
  {
    name: 'rollfold',
    value: 1,
  },
  {
    name: 'zfold',
    value: 0,
  },
];

export const coverpageLogoPosition = [
  {
    name: 'header',
    value: 1,
  },
  {
    name: 'top',
    value: 2,
  },
  {
    name: 'bottom',
    value: 3,
  },
];

export const aggregateNutritionValues = [
  {
    name: 'none',
    value: null,
  },
  {
    name: 'by_day',
    value: 1,
  },
  {
    name: 'by_section',
    value: 2,
  },
  {
    name: 'by_subsection',
    value: 3,
  },
];
